.img{
  display: block;
  margin: 0 auto;
  width: 370px;
  height: 230px;
  box-sizing: border-box;
  box-shadow: 2px 2px 2px 2px #d0d0d0, -2px -2px 5px 1px #d0d0d0;
  overflow: hidden;
  .img-item{
    width: 100%;
    height: 100%;
    transition: transform .2s linear;
    &:hover{
      transform: scale(1.1) rotate(2deg);
    }
  }
}@fontColor: rgba(0, 0, 0, .65); //字体颜色
@selectFontColor: #9960ce; //active字体颜色
@hoverFontColor: #CC6633;
html, body{
  margin: 0;
  padding: 0;
  font: "宋体 Helvetica Neue", Helvetica, "PingFang SC", Tahoma, Arial, sans-serif;
  font-size: 16px;
  background: #f2f2f2;
}

ul{
  list-style: none;
  padding-inline-start: 0;
}

a{
  text-decoration: none;
}
