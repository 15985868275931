.article-list{
  .article-info{
    text-align: right;
  }
  .context{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
  }
  .list-item{
    &:last-child{
      .article-section{
        border: none;
      }
    }
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 2vh 0;
  }
  .article-img{
    width: 40%;
    margin: 2vh 2vw;
  }
 
  .article-section{
    padding-bottom: 20px;
    width: 50%;
    border-bottom: 1px dashed #d2d2d2;
    h3{
      a{
        color: @fontColor;
        cursor: pointer;
        transition: color .3s linear;
        &:hover{
          color: @hoverFontColor;
        }
      }
    }
    .article-info{
      font-size: .8rem;
      span:nth-of-type(1) {
        color: @fontColor;
      }
      span:nth-of-type(2) {
        margin-left: 20px;
        color: @fontColor;
      }
    }
    .context{
      margin: 20px 0;
    }
    .read-more{
      text-align: right;
      font-size: .9rem;
      a{
        color: @selectFontColor;
        cursor: pointer;
        transition: color .3s linear;
        &:hover{
          color: @hoverFontColor;
        }
      }
    }
  }
}@fontColor: rgba(0, 0, 0, .65); //字体颜色
@selectFontColor: #9960ce; //active字体颜色
@hoverFontColor: #CC6633;
html, body{
  margin: 0;
  padding: 0;
  font: "宋体 Helvetica Neue", Helvetica, "PingFang SC", Tahoma, Arial, sans-serif;
  font-size: 16px;
  background: #f2f2f2;
}

ul{
  list-style: none;
  padding-inline-start: 0;
}

a{
  text-decoration: none;
}
