.content{
  margin: 5vh 0;
  h3{
    text-align: center;
  }
  .content-info{
    margin: 2vh;
    text-align: center;
    font-size: .85rem;
    color: @hoverFontColor;
    span{
      margin-left: 20px;
    }
  }
  .content-img{
    .img{
      width: 100%;
      height: 500px;
    }
  }
  .context{
    text-indent: 2em;
    margin: 5vh 0;
    line-height: 45px;
  }
}@fontColor: rgba(0, 0, 0, .65); //字体颜色
@selectFontColor: #9960ce; //active字体颜色
@hoverFontColor: #CC6633;
html, body{
  margin: 0;
  padding: 0;
  font: "宋体 Helvetica Neue", Helvetica, "PingFang SC", Tahoma, Arial, sans-serif;
  font-size: 16px;
  background: #f2f2f2;
}

ul{
  list-style: none;
  padding-inline-start: 0;
}

a{
  text-decoration: none;
}
