.header{
  display: flex;
  padding: 4vh 2vw 2vh 2vw;
  justify-content: space-between;
  align-items: center;
  .logo{
    width: 18vw;
  }
  .nav{
    width: 60vw;
  }
  .slogan{
    width: 20vw;
  }
}@fontColor: rgba(0, 0, 0, .65); //字体颜色
@selectFontColor: #9960ce; //active字体颜色
@hoverFontColor: #CC6633;
html, body{
  margin: 0;
  padding: 0;
  font: "宋体 Helvetica Neue", Helvetica, "PingFang SC", Tahoma, Arial, sans-serif;
  font-size: 16px;
  background: #f2f2f2;
}

ul{
  list-style: none;
  padding-inline-start: 0;
}

a{
  text-decoration: none;
}
