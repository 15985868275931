footer{
	margin: 4vh 0 0 0;
	padding: 2vh 4vw;
	font-size: .75rem;
	background: #e9e9e9;
	border-top: 1px solid #e0e0e0;
	text-align: center;
	.link{
		display: flex;
		justify-content: space-around;
    align-items: center;
    margin-bottom: 4vh;
  }
  .link-container{
    display: flex;
		justify-content: space-around;
    width: 40%;
    margin: 0 10%;
    box-sizing: border-box;
  }
	.link-item-container{
    width: 80px;
    .link-item{
      height: 25px;
    }
		h3{
			font-size: .8rem;
			color: #6a6a6a;
		}
		a{
			color: #909090;
			&:hover{
				color: #402863;
			}
		}
  }
  .qrcode-container{
    width: 100px;
    height: 100px;
    background: url(../../assets/img/qrcode.jpg) no-repeat center center;
    background-size: cover;
  }
	.contact{
    text-align: left;
    width: 25%;
    box-sizing: border-box;
    height: 80px;
    padding-left: 1vw;
    border-left: 3px solid #402863;
    li{
      font-size: .8rem;
    }
  }
  .copyright{
    a{
      color: #999999;
      &:hover{
        color: #402863;
      }
    }
  }
}
@fontColor: rgba(0, 0, 0, .65); //字体颜色
@selectFontColor: #9960ce; //active字体颜色
@hoverFontColor: #CC6633;
html, body{
  margin: 0;
  padding: 0;
  font: "宋体 Helvetica Neue", Helvetica, "PingFang SC", Tahoma, Arial, sans-serif;
  font-size: 16px;
  background: #f2f2f2;
}

ul{
  list-style: none;
  padding-inline-start: 0;
}

a{
  text-decoration: none;
}
