.navbar{
  ul{
    display: flex;
    li{
      width: 10%;
      text-align: center;
    }
  }
}

.ant-menu{
  background: none;
}
.ant-menu-horizontal > .ant-menu-item-selected:hover,
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-submenu,
.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-menu-horizontal > .ant-menu-item-selected{
  border: none;
}
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-item-selected{
  color: #3f2863;
}
.ant-menu-horizontal > .ant-menu-item > a:hover{
  color: @selectFontColor;
}
.ant-menu-horizontal > .ant-menu-item-selected > a{
  color: @selectFontColor;
}@fontColor: rgba(0, 0, 0, .65); //字体颜色
@selectFontColor: #9960ce; //active字体颜色
@hoverFontColor: #CC6633;
html, body{
  margin: 0;
  padding: 0;
  font: "宋体 Helvetica Neue", Helvetica, "PingFang SC", Tahoma, Arial, sans-serif;
  font-size: 16px;
  background: #f2f2f2;
}

ul{
  list-style: none;
  padding-inline-start: 0;
}

a{
  text-decoration: none;
}
